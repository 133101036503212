import { useState } from "react";

import ModalAlert from "../../ModalAlert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Form = ({ onSubmit = () => {}, loading = false }) => {
	const [emailToSendNotification, setEmailToSendNotification] = useState(null);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);

	const [showConfirm, setShowConfirm] = useState(false);

	const today = new Date();

	const onDateChange = (e) => {
		if (!e.target.value) {
			setSelectedMonth(null);
			setSelectedYear(null);
			return;
		}
		setSelectedMonth(e.target.value.split("-")[1].toString().padStart(2, 0));
		setSelectedYear(e.target.value.split("-")[0]);
	};

	return (
		<>
			<div className="d-flex flex-column align-items-start">
				<label>1. Seleccione el mes del que desea regenerar la caché</label>
				<input
					type="month"
					min={`2024-01-01`}
					max={`${today.getFullYear()}-${today.getMonth() + 1}`}
					className="form-control"
					style={{ width: "auto" }}
					onChange={onDateChange}
				/>
			</div>
			<div className="d-flex flex-column align-items-start mt-3">
				<label>
					2. Introduzca la dirección de email donde desea ser notificado
				</label>{" "}
				<input
					type="email"
					className="form-control"
					style={{ width: "auto" }}
					onChange={(e) => setEmailToSendNotification(e.target.value)}
				/>
			</div>

			<div />
			{selectedMonth && (
				<div className="d-flex flex-column align-items-start mt-4">
					<label className="width-100">
						3. Generar caché para el mes seleccionado
					</label>
					<button
						className="btn btn-primary mr-2"
						disabled={!selectedMonth || loading}
						onClick={(e) => {
							e.preventDefault();
							onSubmit(selectedMonth, selectedYear, emailToSendNotification);
						}}
					>
						<span>
							<FontAwesomeIcon icon={faCheck} className="mr-2" /> Regenerar la
							caché del mes {selectedMonth}/{selectedYear}
						</span>
					</button>
				</div>
			)}

			<ModalAlert
				title="Regenerar caché de salas"
				description={`¿Confirmas que deseas regenerar la caché del mes ${selectedMonth}/${selectedYear}?`}
				labelSuccess="Continuar"
				handleCancel={() => setShowConfirm(false)}
				handleSuccess={() => {
					setShowConfirm(false);
					onSubmit({ selectedMonth, selectedYear, emailToSendNotification });
				}}
				show={showConfirm}
			/>
		</>
	);
};

export default Form;
